import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class HelpCenterProvider extends HttpRequest {
  getLastItem () {
    this.setHeader(getAuthToken())
    return this.get('/cms/help-center')
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('/cms/help-center', payload)
  }
}

export default HelpCenterProvider
