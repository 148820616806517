<template>
  <v-container fluid>
    <v-form
      ref="form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          class="px-0">
          <v-btn
            color="primary"
            outlined
            depressed
            :ripple="false"
            style="width: fit-content; background-color: white;"
            @click="onBack()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
            <span>
              ย้อนกลับ
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row class="form-container rounded-lg">
            <v-col cols="12">
              <h3>
                แบนเนอร์ (ติดต่อเรา)
              </h3>
              <v-divider class="mt-3" />
            </v-col>
            <v-col
              class="px-10 pb-10"
              cols="12">
              <v-row class="elevation-3 rounded">
                <v-col cols="12">
                  <image-uploader
                    v-model="images"
                    name="contactBanner"
                    :max-file="1"
                    square
                    :disabled="loading" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-row justify-space-between align-center">
                <h3>
                  คำถามที่พบบ่อย
                </h3>
                <v-btn
                  color="primary"
                  outlined
                  depressed
                  :ripple="false"
                  style="width: fit-content; background-color: white;"
                  @click="addItem()">
                  <span>
                    เพิ่มคำถาม
                  </span>
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
              <v-divider class="mt-3" />
            </v-col>
            <v-col
              v-for="(item, i) in formData.contents"
              :key="`about-us-${i}`"
              class="px-10 pb-10"
              cols="12">
              <v-row class="elevation-3 rounded">
                <v-col cols="12">
                  <div class="mb-1">
                    หัวข้อที่ {{ i + 1 }}
                  </div>
                  <v-text-field
                    v-model="formData.contents[i].title"
                    placeholder="เมื่อไรจะได้รับใบยืนยันการจอง"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <div class="mb-1">
                    คำอธิบาย
                  </div>
                  <v-textarea
                    v-model="formData.contents[i].content"
                    placeholder="โดยปกติแล้วท่านจะได้รับอีเมลยืนยัน..."
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <div class="d-flex justify-end">
                    <v-btn
                      v-if="formData.contents.length > 1"
                      color="error"
                      outlined
                      depressed
                      :ripple="false"
                      style="width: fit-content; background-color: white;"
                      :disabled="loading"
                      @click="removeItem(i)">
                      <v-icon>
                        mdi-trash-can-outline
                      </v-icon>
                      <span>
                        ลบหัวข้อ
                      </span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'
import UploaderProvider from '@/resources/uploader.provider'
import HelpCenterProvider from '@/resources/help-center.provider'

const HelpCenterService = new HelpCenterProvider()
const UploaderService = new UploaderProvider()

export default {
  components: {
    ImageUploader
  },
  data: () => ({
    loading: false,
    images: [],
    formData: {
      banner: '',
      contents: [
        {
          title: '',
          content: ''
        }
      ]
    }
  }),
  mounted () {
    this.getItem()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'Contents' })
    },
    async getItem () {
      try {
        this.loading = true

        const { data } = await HelpCenterService.getLastItem()

        this.formData = data ? { ...data } : this.formData
        this.images = data?.banner ? [{
            file: null,
            preview: data.banner,
            mediaUrl: data.banner
          }] : []
      } catch (error) {
        console.error('getItem', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        if (this.formData.contents.some((v) => !v.content)) {
          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            type: 'error',
            timeout: 2000
          })

          return
        }

        let banner = this.images[0]?.mediaUrl || ''

        if (this.images[0]?.file) {
          const { data: image } = await UploaderService.uploadFile(this.images[0].file)

          banner = image?.publicUrl
        }

        await HelpCenterService.updateItem({
          ...this.formData,
          banner
        })

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไข ข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItem()
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    addItem () {
      this.formData.contents.push({
        title: '',
        content: ''
      })
    },
    removeItem (index) {
      this.formData.contents.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
